import React from 'react';
import { Button, Form, Input, message } from 'antd';
import { useRouter } from 'next/router';

import { api } from 'shared/api';
import { useAuth } from 'entities/auth';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
};

const Login = () => {
  const { push } = useRouter();
  const { login } = useAuth();
  const onFinish = async (values: { username: string; password: string }) => {
    try {
      const { accessToken } = await api.post('/api/login', JSON.stringify(values));
      login(accessToken);
      push('/');
    } catch (e) {
      message.error('Попробуйте снова');
    }
  };

  return (
    <Form {...layout} className="page" name="login" onFinish={onFinish}>
      <Form.Item
        label="Логин"
        name="username"
        rules={[
          {
            required: true,
            message: 'Заполните логин!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Пароль"
        name="password"
        rules={[
          {
            required: true,
            message: 'Заполните пароль!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
        <Button type="primary" htmlType="submit">
          Войти
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;
